<template>
  <div>
    <b-row class="justify-content-center">
      <b-col
        md="11"
        class="mx-2"
      >
        <form-wizard
          color="#039FDB"
          :title="null"
          :subtitle="null"
          shape="square"
          :start-index="currentStep"
          finish-button-text="Soumettre"
          back-button-text="Précédent"
          next-button-text="Suivant"
          class="my-3"
          @on-complete="formSubmitted"
        >

          <!-- Type de contrat -->
          <tab-content
            title="Type de contrat"
            icon="feather icon-user"
            :before-change="validationFormPersonnel"
          >
            <validation-observer
              ref="personnelRules"
              tag="form"
            >
              <b-row>
                <b-col
                  cols="12"
                  class="mb-2 "
                >
                  <h5 class="mb-0">
                    Type de contrat
                  </h5>
                  <small class="text-muted">
                    Informations sur le type de contrat
                  </small>
                </b-col>

                <b-col md="3" />
                <b-col md="6">
                  <b-form-group
                    label="Type de contrat"
                    label-for="type_contrat_id"
                  >
                    <validation-provider
                      #default="{ errors }"
                      name="type de contrat"
                      rules="required"
                    >
                      <v-select
                        id="type_contrat_id"
                        v-model="contrat.type_contrat_id"
                        label="title"
                        :state="errors.length > 0 ? false : null"
                        :options="contractTypes"
                        :reduce="(contractTypes) => contractTypes.id"
                        placeholder="Sélectionner un type de contrat"
                      />
                      <small class="text-danger">{{ errors[0] }}</small>
                    </validation-provider>
                  </b-form-group>
                </b-col>
              </b-row>
            </validation-observer>
          </tab-content>

          <!-- Information sur le bail -->
          <tab-content
            v-if="contrat.type_contrat_id === 1 || contrat.type_contrat_id === 3 || contrat.type_contrat_id === 5"
            title="Bail"
            :before-change="validationFormEtranger"
          >
            <validation-observer
              ref="etrangerRules"
              tag="form"
            >
              <b-row>
                <b-col
                  cols="12"
                  class="mb-2"
                >
                  <h5 class="mb-0">
                    Information sur le bail
                  </h5>
                  <small class="text-muted">Information sur le bail.</small>
                </b-col>
                <b-col md="6">
                  <b-form-group
                    label="Durée du contrat"
                    label-for="duree_contrat"
                  >
                    <validation-provider
                      #default="{ errors }"
                      name="durée du contrat bail"
                      rules="required"
                    >
                      <b-form-radio-group
                        v-model="contrat.duree_contrat"
                        :options="dureeContratList"
                        class="demo-inline-spacing"
                        name="duree_contrat"
                      />
                      <small class="text-danger">{{ errors[0] }}</small>

                    </validation-provider>
                  </b-form-group>

                </b-col>
                <b-col md="6">
                  <b-form-group
                    label="Date de début du bail"
                    label-for="date_debut_bail"
                  >
                    <validation-provider
                      #default="{ errors }"
                      name="date d'expiration du visa"
                      rules="required"
                    >
                      <flat-pickr
                        id="date_debut_bail"
                        v-model="contrat.date_debut_bail"
                        :config="config"
                        class="form-control mb-1"
                        placeholder="Date de début du bail"
                      />
                      <small class="text-danger">{{ errors[0] }}</small>
                    </validation-provider>
                  </b-form-group>
                </b-col>
                <b-col md="6">
                  <b-row>
                    <b-col md="6">
                      <b-form-group
                        label="Durée du bail"
                        label-for="duree_bail"
                      >
                        <validation-provider
                          #default="{ errors }"
                          name="durée du bail"
                          rules="required"
                        >
                          <b-form-input
                            id="duree_bail"
                            v-model="contrat.duree_bail"
                            :state="errors.length > 0 ? 'false' : null"
                            placeholder="Durée du bail"
                          />
                          <small class="text-danger">{{ errors[0] }}</small>
                        </validation-provider>
                      </b-form-group>
                    </b-col>
                    <b-col md="6">
                      <b-form-group
                        label="Durée du contrat"
                        label-for="duree_unite"
                      >
                        <validation-provider
                          #default="{ errors }"
                          name="durée du contrat"
                          rules="required"
                        >
                          <v-select
                            id="duree_unite"
                            v-model="contrat.duree_unite"
                            label="text"
                            :state="errors.length > 0 ? false : null"
                            :options="dureeBailList"
                            :reduce="(dureeBailList) => dureeBailList.text"
                            placeholder="Sélectionner un type de contrat"
                          />
                          <small class="text-danger">{{ errors[0] }}</small>
                        </validation-provider>
                      </b-form-group>
                    </b-col>

                  </b-row>

                </b-col>
                <b-col md="6">
                  <b-form-group
                    label="Date de fin du bail"
                    label-for="date_fin_bail"
                  >
                    <validation-provider
                      #default="{ errors }"
                      name="date de fin du bail"
                      rules="required"
                    >
                      <flat-pickr
                        id="date_fin_bail"
                        v-model="contrat.date_fin_bail"
                        :config="config"
                        class="form-control mb-1"
                        placeholder="Date de fin du bail"
                      />
                      <small class="text-danger">{{ errors[0] }}</small>
                    </validation-provider>
                  </b-form-group>
                </b-col>
                <b-col md="6">
                  <b-form-group
                    label="Date d’alerte de fin contrat"
                    label-for="date_alerte_fin_bail"
                  >
                    <validation-provider
                      #default="{ errors }"
                      name="date d’alerte de fin contrat"
                      rules="required"
                    >
                      <flat-pickr
                        id="date_alerte_fin_bail"
                        v-model="contrat.date_alerte_fin_bail"
                        :config="config"
                        class="form-control mb-1"
                        placeholder="Date d’alerte de fin contrat"
                      />
                      <small class="text-danger">{{ errors[0] }}</small>
                    </validation-provider>
                  </b-form-group>
                </b-col>

                <b-col md="6">
                  <b-form-group
                    label-for="renouvellement"
                    label="Option de renouvellement du contrat"
                  >
                    <validation-provider
                      #default="{ errors }"
                      name="renouvellement"
                      rules="required"
                    >
                      <b-form-radio-group
                        v-model="contrat.renouvellement"
                        :options="isRenouvellement"
                        class="demo-inline-spacing"
                        name="renouvellement"
                      />
                      <small class="text-danger">{{ errors[0] }}</small>
                    </validation-provider>
                  </b-form-group>
                </b-col>
              </b-row>
            </validation-observer>
          </tab-content>

          <!-- Termes et conditions du bail  -->
          <tab-content
            v-if="contrat.type_contrat_id === 1 || contrat.type_contrat_id === 3 || contrat.type_contrat_id === 5"
            title="Termes et conditions du bail"
            :before-change="validationFormSituationMatrimoniale"
          >
            <validation-observer
              ref="situationMatrimonialeRules"
              tag="form"
            >
              <b-row>
                <b-col
                  cols="12"
                  class="mb-2"
                >
                  <h5 class="mb-0">
                    Termes et conditions du bail
                  </h5>
                  <small class="text-muted">Informations sur la situation matrimoniale.</small>
                </b-col>

                <b-col md="6">
                  <b-form-group
                    label-for="depot_garantie"
                    label="Dépôt de garantie ou caution"
                  >
                    <validation-provider
                      #default="{ errors }"
                      name="dépôt de garantie ou caution"
                      rules="required"
                    >
                      <b-form-radio-group
                        v-model="contrat.depot_garantie"
                        :options="isLeader"
                        class="demo-inline-spacing"
                        name="depot_garantie"
                      />
                      <small class="text-danger">{{ errors[0] }}</small>

                    </validation-provider>
                  </b-form-group>

                </b-col>
                <b-col
                  v-if="contrat.depot_garantie === true"
                  md="6"
                >
                  <b-form-group
                    label="Montant de la garrantie"
                    label-for="montant_depot_garantie"
                  >
                    <validation-provider
                      #default="{ errors }"
                      name="montant de la garrantie"
                      rules="required"
                    >
                      <cleave
                        id="montant_depot_garantie"
                        v-model="contrat.montant_depot_garantie"
                        placeholder="Montant de la garrantie"
                        class="form-control"
                        :raw="false"
                        :options="number"
                      />
                      <small class="text-danger">{{ errors[0] }}</small>

                    </validation-provider>
                  </b-form-group>

                </b-col>

                <!-- <b-col md="6">
                  <b-form-group
                    label-for="frais_dentretien"
                    label="Frais d'entretien"
                  >
                    <b-form-radio-group
                      v-model="contrat.frais_dentretien"
                      :options="isLeader"
                      class="demo-inline-spacing"
                      name="frais_dentretien"
                    />
                  </b-form-group>
                </b-col> -->
                <b-col
                  md="6"
                >
                  <b-form-group
                    label="Facture adressée à"
                    label-for="responsable_facture_entretien"
                  >
                    <validation-provider
                      #default="{ errors }"
                      name="responsable facture d'entretien"
                      rules="required"
                    >
                      <b-form-radio-group
                        v-model="contrat.responsable_facture_entretien"
                        :options="isResponsableEntretioen"
                        class="demo-inline-spacing"
                        name="responsable_facture_entretien"
                      />
                      <small class="text-danger">{{ errors[0] }}</small>

                    </validation-provider>
                  </b-form-group>

                </b-col>

                <b-col md="6">
                  <b-form-group
                    label-for="politique_animale"
                    label="Politiques sur les animaux de compagnie "
                  >
                    <b-form-radio-group
                      v-model="contrat.politique_animale"
                      :options="isAuthorize"
                      class="demo-inline-spacing"
                      name="politique_animale"
                    />
                  </b-form-group>
                </b-col>
                <b-col md="6">
                  <b-form-group
                    label-for="politique_non_fumeur"
                    label="Politiques de non-fumeurs "
                  >
                    <b-form-radio-group
                      v-model="contrat.politique_non_fumeur"
                      :options="isAuthorize"
                      class="demo-inline-spacing"
                      name="politique_non_fumeur"
                    />
                  </b-form-group>
                </b-col>
                <b-col md="6">
                  <b-form-group
                    label="Modalité de résiliation anticipée"
                    label-for="modalite_resiliation"
                  >
                    <validation-provider
                      #default="{ errors }"
                      name="modalité de résiliation anticipée"
                      rules="required"
                    >
                      <b-form-radio-group
                        v-model="contrat.modalite_resiliation"
                        :options="isResponsableEntretioen"
                        class="demo-inline-spacing"
                        name="modalite_resiliation"
                      />
                      <small class="text-danger">{{ errors[0] }}</small>

                    </validation-provider>
                  </b-form-group>

                </b-col>
                <b-col
                  md="6"
                >
                  <b-form-group
                    label="Responsabilités de chaque partie en matière d'entretien"
                    label-for="responsabilite_entretien"
                  >
                    <validation-provider
                      #default="{ errors }"
                      name="responsabilités des parties"
                      rules="required"
                    >
                      <b-form-input
                        id="responsabilite_entretien"
                        v-model="contrat.responsabilite_entretien"
                        placeholder="Responsabilités de chaque partie en matière d'entretien"
                      />
                      <small class="text-danger">{{ errors[0] }}</small>

                    </validation-provider>
                  </b-form-group>

                </b-col>

              </b-row>
            </validation-observer>
          </tab-content>

          <!-- Contrat de type bail résidentiel Informations sur le paiement -->
          <tab-content
            v-if="contrat.type_contrat_id === 1 || contrat.type_contrat_id === 3 || contrat.type_contrat_id === 5"
            title="Modalités de paiement"
            :before-change="validationFormInfoPartenaire"
          >
            <validation-observer
              ref="infoPartenaireRules"
              tag="form"
            >
              <b-row>
                <b-col
                  cols="12"
                  class="mb-2"
                >
                  <h5 class="mb-0">
                    Modalités de paiement
                  </h5>
                  <small class="text-muted">Modalités de paiement</small>
                </b-col>

                <b-col md="6">
                  <b-form-group
                    label="Date d'échéance du loyer"
                    label-for="date_paiement_loyer"
                  >
                    <validation-provider
                      #default="{ errors }"
                      name="date d'échéance du loyer"
                      rules="required"
                    >
                      <flat-pickr
                        id="date_paiement_loyer"
                        v-model="contrat.date_paiement_loyer"
                        :config="config"
                        class="form-control mb-1"
                        placeholder="Date d'échéance du loyer"
                      />
                      <small class="text-danger">{{ errors[0] }}</small>
                    </validation-provider>
                  </b-form-group>
                </b-col>

                <b-col md="6">
                  <b-form-group
                    label-for="echeancier_paiement"
                    label="Échéancier des paiements "
                  >
                    <validation-provider
                      #default="{ errors }"
                      name="échéancier des paiements"
                      rules="required"
                    >

                      <v-select
                        id="echeancier_paiement"
                        v-model="contrat.echeancier_paiement"
                        label="text"
                        :state="errors.length > 0 ? false : null"
                        :options="echancePayementListe"
                        :reduce="(echancePayementListe) => echancePayementListe.text"
                        placeholder="Sélectionner un type de contrat"
                      />
                      <small class="text-danger">{{ errors[0] }}</small>

                    </validation-provider>
                  </b-form-group>

                </b-col>

                <b-col md="6">
                  <b-form-group
                    label-for="mode_paiement"
                    label="Méthode de paiement acceptée"
                  >
                    <validation-provider
                      #default="{ errors }"
                      name="méthode de paiement acceptée"
                      rules="required"
                    >
                      <b-form-radio-group
                        v-model="contrat.mode_paiement"
                        :options="isPayementMethod"
                        class="demo-inline-spacing"
                        name="mode_paiement"
                      />
                      <small class="text-danger">{{ errors[0] }}</small>

                    </validation-provider>
                  </b-form-group>

                </b-col>
              </b-row>
            </validation-observer>
          </tab-content>

          <!-- Contrat de type achat de vente -->
          <tab-content
            v-if="contrat.type_contrat_id === 2 || contrat.type_contrat_id === 4"
            title="Conditions financières"
            :before-change="validationFormEtranger"
          >
            <validation-observer
              ref="etrangerRules"
              tag="form"
            >
              <b-row>
                <b-col
                  cols="12"
                  class="mb-2"
                >
                  <h5 class="mb-0">
                    Conditions financières
                  </h5>
                  <small class="text-muted">Conditions financières .</small>
                </b-col>
                <b-col md="6">
                  <b-form-group
                    label="Prix d'achat convenu"
                    label-for="prix_achat"
                  >
                    <validation-provider
                      #default="{ errors }"
                      name="prix d'achat convenu"
                      rules="required"
                    >
                      <cleave
                        id="prix_achat"
                        v-model="contratVente.prix_achat"
                        placeholder="Prix d'achat convenu"
                        class="form-control"
                        :raw="false"
                        :options="number"
                      />
                      <small class="text-danger">{{ errors[0] }}</small>

                    </validation-provider>
                  </b-form-group>
                </b-col>
                <b-col md="6">
                  <b-form-group
                    label="Montant de l'acompte versé"
                    label-for="montant_acompte"
                  >
                    <validation-provider
                      #default="{ errors }"
                      name="montant de l'acompte versé"
                      rules="required"
                    >
                      <cleave
                        id="montant_acompte"
                        v-model="contratVente.montant_acompte"
                        placeholder="Montant de l'acompte versé"
                        class="form-control"
                        :raw="false"
                        :options="number"
                      />
                      <small class="text-danger">{{ errors[0] }}</small>

                    </validation-provider>
                  </b-form-group>
                </b-col>

                <b-col md="6">
                  <b-form-group
                    label="Montant du prêt hypothécaire"
                    label-for="montant_pret_hypothecaire"
                  >
                    <cleave
                      id="montant_pret_hypothecaire"
                      v-model="contratVente.montant_pret_hypothecaire"
                      placeholder="Montant du prêt hypothécaire"
                      class="form-control"
                      :raw="false"
                      :options="number"
                    />
                  </b-form-group>
                </b-col>

                <b-col md="6">
                  <b-form-group
                    label="Taux d'intérêt"
                    label-for="taux"
                  >
                    <b-form-input
                      id="taux"
                      v-model="contratVente.taux"
                      placeholder="Taux d'intérêt"
                      type="number"
                    />
                  </b-form-group>
                </b-col>
                <b-col md="6">
                  <b-form-group
                    label="Durée du contrat (nombre d'années)"
                    label-for="duree_contrat"
                  >
                    <validation-provider
                      #default="{ errors }"
                      name="durée du contrat"
                      rules="required"
                    >
                      <b-form-input
                        id="duree_contrat"
                        v-model="contratVente.duree_contrat"
                        placeholder="Durée du contrat (nombre d'années)"
                        type="number"
                      />
                      <small class="text-danger">{{ errors[0] }}</small>

                    </validation-provider>
                  </b-form-group>
                </b-col>

              </b-row>
            </validation-observer>
          </tab-content>

          <!-- CONDITION DE PAIEMENT TYPE CONTRAT VENTE -->
          <tab-content
            v-if="contrat.type_contrat_id === 2 || contrat.type_contrat_id === 4"
            title="Conditions de paiement "
            :before-change="validationFormPersonneContacter"
          >
            <validation-observer
              ref="personneContacterRules"
              tag="form"
            >
              <b-row>
                <b-col
                  cols="12"
                  class="mb-2"
                >
                  <h5 class="mb-0">
                    Conditions de paiement
                  </h5>
                  <small class="text-muted">Conditions de paiement </small>
                </b-col>

                <b-col md="6">
                  <b-form-group
                    label-for="echeancier_paiement"
                    label="Échéancier des paiements "
                  >
                    <validation-provider
                      #default="{ errors }"
                      name="échéancier des paiements"
                      rules="required"
                    >

                      <v-select
                        id="echeancier_paiement"
                        v-model="contratVente.echeancier_paiement"
                        label="text"
                        :state="errors.length > 0 ? false : null"
                        :options="echancePayementListe"
                        :reduce="(echancePayementListe) => echancePayementListe.text"
                        placeholder="Sélectionner un type de contrat"
                      />
                      <small class="text-danger">{{ errors[0] }}</small>

                    </validation-provider>
                  </b-form-group>
                </b-col>
                <b-col md="6">
                  <b-form-group
                    label="Montant à payer "
                    label-for="montant_a_payer"
                  >
                    <validation-provider
                      #default="{ errors }"
                      name="montant à payer "
                      rules="required"
                    >
                      <cleave
                        id="montant_a_payer"
                        v-model="contratVente.montant_a_payer"
                        placeholder="Montant à payer "
                        class="form-control"
                        :raw="false"
                        :options="number"
                      />
                      <small class="text-danger">{{ errors[0] }}</small>

                    </validation-provider>
                  </b-form-group>
                </b-col>
                <b-col md="6">
                  <b-form-group
                    label="Reste à payer"
                    label-for="reste_a_payer"
                  >
                    <validation-provider
                      #default="{ errors }"
                      name="reste à payer"
                      rules="required"
                    >
                      <cleave
                        id="reste_a_payer"
                        v-model="contratVente.reste_a_payer"
                        placeholder="Reste à payer"
                        class="form-control"
                        :raw="false"
                        :options="number"
                      />
                      <small class="text-danger">{{ errors[0] }}</small>

                    </validation-provider>
                  </b-form-group>
                </b-col>
                <b-col md="6">
                  <b-form-group
                    label-for="mode_paiement"
                    label="Méthode de paiement acceptée "
                  >
                    <validation-provider
                      #default="{ errors }"
                      name="mode de payement"
                      rules="required"
                    >
                      <b-form-radio-group
                        v-model="contratVente.mode_paiement"
                        :options="isPayementMethod"
                        class="demo-inline-spacing"
                        name="mode_paiement"
                      />
                      <small class="text-danger">{{ errors[0] }}</small>

                    </validation-provider>
                  </b-form-group>
                </b-col>

              </b-row>
            </validation-observer>
          </tab-content>
          <!-- Date de début et de fin du contrat TYPE CONTRAT VENTE  -->
          <tab-content
            v-if="contrat.type_contrat_id === 2 || contrat.type_contrat_id === 4"
            title="Date de début et de fin du contrat"
            :before-change="validationFormDateDebutVente"
          >
            <validation-observer
              ref="dateDebutVenteRules"
              tag="form"
            >
              <b-row>
                <b-col
                  cols="12"
                  class="mb-2"
                >
                  <h5 class="mb-0">
                    Date de début et de fin du contrat
                  </h5>
                  <small class="text-muted">Date de début et de fin du contrat .</small>
                </b-col>

                <b-col md="6">
                  <b-form-group
                    label="Date de début du contrat"
                    label-for="date_debut_contrat"
                  >
                    <validation-provider
                      #default="{ errors }"
                      name="date de début du contrat"
                      rules="required"
                    >
                      <flat-pickr
                        id="date_debut_contrat"
                        v-model="contratVente.date_debut_contrat"
                        :config="config"
                        class="form-control mb-1"
                        placeholder="Date de début du contrat"
                      />
                      <small class="text-danger">{{ errors[0] }}</small>
                    </validation-provider>
                  </b-form-group>
                </b-col>
                <b-col md="6">
                  <b-form-group
                    label="Date d'alerte de fin de contrat"
                    label-for="date_alerte_fin_bail"
                  >
                    <validation-provider
                      #default="{ errors }"
                      name="date de alert fin de bail"
                      rules="required"
                    >
                      <flat-pickr
                        id="date_alerte_fin_bail"
                        v-model="contratVente.date_alerte_fin_bail"
                        :config="config"
                        class="form-control mb-1"
                        placeholder="Date de alert fin de bail"
                      />
                      <small class="text-danger">{{ errors[0] }}</small>
                    </validation-provider>
                  </b-form-group>
                </b-col>
                <b-col md="6">
                  <b-form-group
                    label="Date de clôture contrat"
                    label-for="date_cloture_contrat"
                  >
                    <validation-provider
                      #default="{ errors }"
                      name="date de clôture contrat"
                      rules="required"
                    >
                      <flat-pickr
                        id="date_cloture_contrat"
                        v-model="contratVente.date_cloture_contrat"
                        :config="config"
                        class="form-control mb-1"
                        placeholder="Date de clôture contrat"
                      />
                      <small class="text-danger">{{ errors[0] }}</small>
                    </validation-provider>
                  </b-form-group>
                </b-col>

              </b-row>
            </validation-observer>
          </tab-content>
          <tab-content
            title="Le ficher pdf du contrat"
            :before-change="validationFormContratPdfFile"
          >
            <validation-observer
              ref="formPdfRules"
              tag="form"
            >
              <b-row>
                <b-col
                  cols="12"
                  class="mb-2"
                >
                  <h5 class="mb-0">
                    Le ficher pdf du contrat
                  </h5>
                  <small class="text-muted">Le ficher pdf du contrat .</small>
                </b-col>

                <b-col md="6">
                  <b-form-group
                    label="fichier du contrat"
                    label-for="fichier_contrat"
                  >
                    <validation-provider
                      #default="{ errors }"
                      name="fichier du contrat"
                      rules="required"
                    >
                      <b-form-file
                        v-model="contrat.fichier_contrat"
                        placeholder="Selectionner votre contrat..."
                        drop-placeholder="Votre fichier ici..."
                        accept=".pdf"
                        @change="handlePdfSelected"
                      />
                      <small class="text-danger">{{ errors[0] }}</small>
                    </validation-provider>
                  </b-form-group>
                </b-col>
                <b-col
                  v-if="contrat.type_contrat_id === 1 || contrat.type_contrat_id === 3 || contrat.type_contrat_id === 5"
                  md="6"
                >
                  <b-form-group
                    label="Etat des lieux"
                    label-for="piece_etat_lieux"
                  >
                    <validation-provider
                      #default="{ errors }"
                      name="Etat des lieux"
                      rules="required"
                    >
                      <b-form-file
                        v-model="contrat.piece_etat_lieux"
                        placeholder="Selectionner votre pièces..."
                        drop-placeholder="Votre fichier ici..."
                        accept=".pdf"
                        @change="handlePiecePdfSelected"
                      />
                      <small class="text-danger">{{ errors[0] }}</small>
                    </validation-provider>
                  </b-form-group>
                </b-col>

              </b-row>
            </validation-observer>
          </tab-content>

        </form-wizard>

        <b-modal
          id="modal-enfant"
          ref="myModal"
          no-close-on-backdrop
          button-size="sm"
          title="Ajouter un enfant"
          ok-title="Ajouter"
          cancel-title="Fermer"
          cancel-variant="gradient-danger"
          ok-variant="gradient-success"
          @show="resetEnfantForm"
          @ok="submitEnfantForm"
        >
          <validation-observer ref="enfantAddRules">
            <form @submit.prevent="handleEnfantSubmitModal">
              <b-form-group
                label="Nom de l'enfant"
                label-for="nom"
              >
                <validation-provider
                  #default="{ errors }"
                  name="nom"
                  rules="required"
                >
                  <b-form-input
                    id="nom"
                    v-model="enfantForm.nom"
                    size="sm"
                    :state="errors.length > 0 ? false : null"
                    placeholder="Nom de l'enfant"
                  />
                  <small class="text-danger">{{ errors[0] }}</small>
                </validation-provider>
              </b-form-group>
              <b-form-group
                label="Prénoms de l'enfant"
                label-for="prenom"
              >
                <validation-provider
                  #default="{ errors }"
                  name="prénoms"
                  rules="required"
                >
                  <b-form-input
                    id="prenom"
                    v-model="enfantForm.prenom"
                    size="sm"
                    :state="errors.length > 0 ? false : null"
                    placeholder="Prénoms de l'enfant"
                  />
                  <small class="text-danger">{{ errors[0] }}</small>
                </validation-provider>
              </b-form-group>
              <b-form-group
                label="Date de naissance"
                label-for="date_naissance_enfant"
              >
                <validation-provider
                  #default="{ errors }"
                  name="date de naissance"
                  rules="required"
                >
                  <flat-pickr
                    id="date_naissance_enfant"
                    v-model="enfantForm.date_naissance"
                    :config="config"
                    class="form-control mb-1"
                    placeholder="Date de naissance"
                  />
                  <small class="text-danger">{{ errors[0] }}</small>
                </validation-provider>
              </b-form-group>

              <b-form-group
                label-for="genre"
                label="Genre"
              >
                <b-form-radio-group
                  v-model="enfantForm.genre"
                  :options="enfantGenre"
                  class="demo-inline-spacing"
                  name="genre"
                />
              </b-form-group>
              <b-form-group
                label-for="is_ij"
                label="Est inscrit à IJ?"
              >
                <b-form-radio-group
                  v-model="enfantForm.is_ij"
                  :options="inscritIj"
                  class="demo-inline-spacing"
                  name="is_ij"
                />
              </b-form-group>

            </form>
          </validation-observer>
        </b-modal>
      </b-col>
    </b-row>

  </div>
</template>

<script>
import axios from 'axios'
// eslint-disable-next-line import/no-cycle
import store from '@/store'
// eslint-disable-next-line import/no-cycle
import router from '@/router'

import Ripple from 'vue-ripple-directive'
import { FormWizard, TabContent } from 'vue-form-wizard'
import vSelect from 'vue-select'
import { ValidationProvider, ValidationObserver } from 'vee-validate'
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'
import 'vue-form-wizard/dist/vue-form-wizard.min.css'

import Cleave from 'vue-cleave-component'
// eslint-disable-next-line import/no-extraneous-dependencies
import 'cleave.js/dist/addons/cleave-phone.us'

import {
  BRow,
  BCol,
  BFormGroup,
  BFormInput,
  BFormRadioGroup,
  VBModal,
  BFormFile,
} from 'bootstrap-vue'
import { required, email } from '@validations'
import {
  onMounted, reactive, ref, watch,
} from '@vue/composition-api'
// eslint-disable-next-line import/no-extraneous-dependencies, import/extensions
import { French } from 'flatpickr/dist/l10n/fr.js'
import flatPickr from 'vue-flatpickr-component'
import { $themeConfig } from '@themeConfig'
// eslint-disable-next-line import/no-cycle
import useProprietes from '@/composables/proprieteService'
// eslint-disable-next-line import/no-cycle
import useImageUpload from '@/composables/upload/useImageUpload'

export default {
  components: {
    ValidationProvider,
    ValidationObserver,
    FormWizard,
    TabContent,
    BRow,
    BCol,
    BFormGroup,
    BFormInput,
    vSelect,
    flatPickr,
    BFormRadioGroup,
    BFormFile,
    // eslint-disable-next-line vue/no-unused-components
    ToastificationContent,
    Cleave,

  },
  directives: {
    'b-modal': VBModal,
    Ripple,
  },
  setup() {
    const axiosClient = axios.create({
      baseURL: 'http://harvest-api.test/api',
    })

    axiosClient.interceptors.request.use(
      configuration => {
        // eslint-disable-next-line no-prototype-builtins
        if (store.state.authStore.hasOwnProperty('token')) {
          // eslint-disable-next-line no-param-reassign
          configuration.headers.Authorization = `Bearer ${store.state.authStore.token}`
        }
        // eslint-disable-next-line no-param-reassign
        configuration.headers.withCredentials = true
        return configuration
      },
      error => Promise.reject(error),

    )
    const departements = ref([])

    const familles = ref([])
    const { appLogoImage } = $themeConfig.app
    const {
      recrutementSuccess,

      updateContractSimple, getContractById, contratsSimples, demande, getContractTypes, contractTypes, createContractAchatVente,
    } = useProprietes()
    const {
      imageUrl, imageFile, handlePdfSelected, handlePiecePdfSelected, pdfFile, uploadPdfFile, uploadPdfFileEtatDesLieux, fileName, fileNameEtat,
    } = useImageUpload()
    const form = reactive({
      nom: '',
      prenom: '',
      date_naissance: '',
      nationalite: '',
      adresse: '',
      ville: '',
      formation: '',
      profession: '',
      type_contrat_id: '',
      telephone: '',
      telephonefull: '',
      email: '',

    })
    const contrat = reactive({
      type_contrat_id: null,
      duree_unite: '',
      date_debut_bail: '',
      duree_bail: '',
      duree_contrat: '',
      date_fin_bail: '',
      date_alerte_fin_bail: '',
      renouvellement: '',
      depot_garantie: '',
      montant_depot_garantie: '',
      responsable_facture_entretien: '',
      politique_animale: '',
      politique_non_fumeur: '',
      modalite_resiliation: '',
      date_paiement_loyer: '',
      mode_paiement: '',
      fichier_contrat: '',

      proprietaire: '',
      demande_logement_id: '',
      type_propriete_id: '',
      villa_id: '',
      appartement_id: '',
      agence_immobiliere: '',
      responsabilite_entretien: '',
      piece_etat_lieux: '',
      echeancier_paiement: '',

      frais_dentretien: '',

    })
    const contratVente = reactive({
      vendeur: '',
      acheteur: '',
      agence_immobiliere: '',
      villa_id: '',
      appartement_id: '',

      prix_achat: '',
      montant_acompte: '',
      montant_pret_hypothecaire: '',
      taux: '',
      duree_contrat: '',
      echeancier_paiement: '',
      montant_a_payer: '',
      reste_a_payer: '',
      date_debut_contrat: '',

      date_cloture_contrat: '',
      date_alerte_fin_bail: '',

      mode_paiement: '',
      fichier_contrat: '',
      edit_authorize: '',

    })
    const currentStep = ref(0)
    const situationProfessionnelle = ref([
      { value: 'select_value', text: 'Sélectionner' },
      { value: 'Simple', text: 'Simple' },
      { value: 'Employé', text: 'Employé' },

    ])

    const number = reactive(
      { numeral: true, delimiter: ' ', numeralThousandsGroupStyle: 'thousand' },
    )

    const formations = ref([
      { value: 'select_value', text: 'Sélectionner' },
      { value: '101', text: '101' },
      { value: '201', text: '201' },
      { value: '301', text: '301' },
      { value: 'BDR', text: 'BDR' },
      { value: 'Poimaino', text: 'Poimaino' },
      { value: 'Piliers', text: 'Piliers' },
      { value: 'Impact Academy', text: 'Impact Academy' },
      { value: 'Ecole des leaders', text: 'Ecole des leaders' },

    ])
    const typeVisa = ref([
      { value: 'select_value', text: 'Sélectionner' },
      { value: 'Etudes', text: 'Etudes' },
      { value: 'Travail', text: 'Travail' },
      { value: 'Autre', text: 'Autre' },

    ])
    const situationMatrimoniale = ref([
      { value: 'select_value', text: 'Sélectionner' },
      { value: 'Marié', text: 'Marié' },
      { value: 'Fiancé', text: 'Fiancé' },
      { value: 'Engagé', text: 'Engagé' },
      { value: 'Célibataire', text: 'Célibataire' },

    ])
    const echancePayementListe = ref([
      { value: 'select_value', text: 'Sélectionner' },
      { value: 'Journalier', text: 'Journalier' },
      { value: 'Hebdomadaire', text: 'Hebdomadaire' },
      { value: 'Mensuel', text: 'Mensuel' },
      { value: 'Bimestriel', text: 'Bimestriel' },
      { value: 'Trimestriel', text: 'Trimestriel' },
      { value: 'Semestriel', text: 'Semestriel' },
      { value: 'Annuel', text: 'Annuel' },
    ])
    const etapesMariage = ref([
      { text: 'Dot', value: 'Dot' },
      { text: 'Mariage civil', value: 'Mariage civil' },
      { text: 'Bénédiction nuptiale', value: 'Bénédiction nuptiale' },
    ])
    const isLeader = ref([
      { text: 'Oui', value: true },
      { text: 'Non', value: false },
    ])
    const isRenouvellement = ref([
      { text: 'Oui', value: true },
      { text: 'Non', value: false },
    ])
    const dureeContratList = ref([
      { text: 'Déterminée', value: 'Déterminée' },
      { text: 'Indéterminée', value: 'Indéterminée' },
    ])
    const dureeBailList = ref([
      { text: 'jours', value: 'jours' },
      { text: 'mois', value: 'mois' },
      { text: 'ans', value: 'ans' },
    ])
    const isResponsableEntretioen = ref([
      { text: 'Locataire', value: 'Locataire' },
      { text: 'Bailleur', value: 'Bailleur' },
    ])
    const isAuthorize = ref([
      { text: 'Autorisé', value: 'Autorisé' },
      { text: 'Non autorisé', value: 'Non autorisé' },
    ])
    const isPayementMethod = ref([
      { text: 'Mobile Money', value: 'Mobile Money' },
      { text: 'Virement', value: 'Virement' },
      { text: 'Paiement au comptant', value: 'Paiement au comptant' },
    ])

    const cureDame = ref([
      { text: 'Oui', value: true },
      { text: 'Non', value: false },
    ])

    const isMembreIcc = ref([
      { text: 'Oui', value: true },
      { text: 'Non', value: false },
    ])
    const enfantGenre = ref([
      { text: 'Masculin', value: 'Masculin' },
      { text: 'Féminin', value: 'Féminin' },
    ])

    const derniereCureDame = ref([
      { text: 'ICC', value: 'ICC' },
      { text: 'Ailleurs', value: 'Ailleurs' },
    ])

    const inscritIj = ref([
      { text: 'Oui', value: 'Oui' },
      { text: 'Non', value: 'Non' },
    ])
    const config = {
      wrap: true, // set wrap to true only when using 'input-group'
      altFormat: 'j M Y',
      altInput: true,
      enableTime: false,
      dateFormat: 'Y-m-d',
      locale: French, // locale for this instance only
    }

    const fieldsEnfant = reactive([
      { key: 'nom', label: 'Nom' },
      { key: 'prenom', label: 'Prénoms' },
      { key: 'date_naissance', label: 'Date de naissance' },
      { key: 'genre', label: 'Genre' },
      { key: 'is_ij', label: 'Est inscrit à IJ?' },
      { key: 'actions' },
    ])
    const myModal = ref('')
    const telephonefull = ref('')
    const telephonefullLeader = ref('')
    const telephonefullPartenaire = ref('')
    const telephonePersonneContacterfull = ref('')
    const personnelRules = ref('')
    const etrangerRules = ref('')
    const situationMatrimonialeRules = ref('')
    const dateDebutVenteRules = ref('')
    const infoPartenaireRules = ref('')
    const enfantRules = ref('')
    const enfantAddRules = ref('')
    const parcoursSpirituelRules = ref('')
    const formPdfRules = ref('')
    const aptitudeRules = ref('')
    const personneContacterRules = ref('')
    const enfantForm = ref({})
    const items = ref([])

    // Reset Form
    const resetEnfantForm = () => {
      enfantForm.value = {}
    }

    const handleEnfantSubmitModal = () => {
      enfantAddRules.value.validate().then(async success => {
        if (success) {
          if (form.enfants.length) enfantForm.value.id = form.enfants[form.enfants.length - 1].id + 1
          else enfantForm.value.id = 1

          form.enfants.push(enfantForm.value)
          items.value = form.enfants
          myModal.value.toggle('#toggle-btn')
        }
      })
    }

    //  Fonction de soumission du modal
    const submitEnfantForm = bvModalEvt => {
      // Prevent modal from closing
      bvModalEvt.preventDefault()
      // Trigger submit handler
      handleEnfantSubmitModal()
    }

    const removeItem = async data => {
      console.clear()
      const indexOfObject = items.value.findIndex(object => object.id === data.item.id)
      items.value.splice(indexOfObject, 1)
    }

    // eslint-disable-next-line no-unused-vars
    watch(() => (contrat.depot_garantie), depot => {
      if (depot === false) {
        contrat.montant_depot_garantie = null
      }
      console.clear()
    })

    const validationFormPersonnel = () => new Promise((resolve, reject) => {
      personnelRules.value.validate().then(success => {
        if (success) resolve(true)
        else reject()
      })
    })

    const validationFormEtranger = () => new Promise((resolve, reject) => {
      etrangerRules.value.validate().then(success => {
        if (success) resolve(true)
        else reject()
      })
    })

    const validationFormSituationMatrimoniale = () => new Promise((resolve, reject) => {
      situationMatrimonialeRules.value.validate().then(success => {
        if (success) resolve(true)
        else reject()
      })
    })
    const validationFormDateDebutVente = () => new Promise((resolve, reject) => {
      dateDebutVenteRules.value.validate().then(success => {
        if (success) resolve(true)
        else reject()
      })
    })

    const validationFormInfoPartenaire = () => new Promise((resolve, reject) => {
      infoPartenaireRules.value.validate().then(success => {
        if (success) resolve(true)
        else reject()
      })
    })

    const validationFormEnfant = () => new Promise((resolve, reject) => {
      enfantRules.value.validate().then(success => {
        if (success) resolve(true)
        else reject()
      })
    })

    const validationFormParcoursSpirituel = () => new Promise((resolve, reject) => {
      parcoursSpirituelRules.value.validate().then(success => {
        if (success) resolve(true)
        else reject()
      })
    })
    const validationFormContratPdfFile = () => new Promise((resolve, reject) => {
      formPdfRules.value.validate().then(success => {
        if (success) resolve(true)
        else reject()
      })
    })

    const validationFormAptitude = () => new Promise((resolve, reject) => {
      aptitudeRules.value.validate().then(success => {
        if (success) resolve(true)
        else reject()
      })
    })

    const validationFormPersonneContacter = () => new Promise((resolve, reject) => {
      personneContacterRules.value.validate().then(success => {
        if (success) resolve(true)
        else reject()
      })
    })

    const upload = async () => {
      const result = imageFile.value instanceof File
      if (result) {
        const data = new FormData()
        data.append('fichier', imageFile.value)
        await uploadPdfFile(data)
        contrat.fichier_contrat = fileName.value
        contratVente.fichier_contrat = fileName.value
        contrat.temporay_url = imageUrl.value

        console.log(contrat)
        // if (oldImage.value !== null) await deleteFile(oldImage.value, 'uploads')
      }
    }
    const uploadEtatDesLieux = async () => {
      const result = pdfFile.value instanceof File
      if (result) {
        const data = new FormData()
        data.append('fichier', pdfFile.value)
        await uploadPdfFileEtatDesLieux(data)
        contrat.piece_etat_lieux = fileNameEtat.value
        contrat.etat_des_lieux_temporay_url = imageUrl.value
        // if (oldImage.value !== null) await deleteFile(oldImage.value, 'uploads')
      }
    }

    const formSubmitted = async () => {
      // agence_immobiliere
      // duree_unite
      // responsabilite_entretien

      await upload()
      await uploadEtatDesLieux()

      if (contrat.type_contrat_id === 1 || contrat.type_contrat_id === 3 || contrat.type_contrat_id === 5) {
        console.log(contrat)
        await updateContractSimple(contrat)
      } else {
        contratVente.type_contrat_id = contrat.type_contrat_id

        contratVente.vendeur = demande.value.proprietaire_id

        //   mode_paiement
        await createContractAchatVente(contratVente)
      }
      if (recrutementSuccess.value) {
        Object.assign(form, {})
      }
    }

    onMounted(async () => {
      await getContractTypes()
      await getContractById(router.currentRoute.params.id)
      console.log(contratsSimples)

      contrat.id = contratsSimples.value.id
      contrat.copropriete = contratsSimples.value.copropriete_id
      contrat.duree_unite = contratsSimples.value.duree_unite
      contrat.date_debut_bail = contratsSimples.value.date_debut_bail
      contrat.duree_bail = contratsSimples.value.duree_bail
      contrat.duree_contrat = contratsSimples.value.duree_contrat
      contrat.date_fin_bail = contratsSimples.value.date_fin_bail
      contrat.date_alerte_fin_bail = contratsSimples.value.date_alerte_fin_bail
      contrat.renouvellement = contratsSimples.value.renouvellement
      contrat.depot_garantie = contratsSimples.value.depot_garantie
      contrat.montant_depot_garantie = contratsSimples.value.montant_depot_garantie
      contrat.responsable_facture_entretien = contratsSimples.value.responsable_facture_entretien
      contrat.politique_animale = contratsSimples.value.politique_animale
      contrat.politique_non_fumeur = contratsSimples.value.politique_non_fumeur
      contrat.modalite_resiliation = contratsSimples.value.modalite_resiliation
      contrat.date_paiement_loyer = contratsSimples.value.date_paiement_loyer
      contrat.mode_paiement = contratsSimples.value.mode_paiement
      contrat.fichier_contrat = contratsSimples.value.fichier_contrat
      contrat.demande_logement_id = contratsSimples.value.demande_logement_id
      if (contratsSimples.value.villa_id) {
        contrat.type_propriete_id = contratsSimples.value.villa.type_propriete_id
        contrat.villa_id = contratsSimples.value.villa_id
      }
      if (contratsSimples.value.appartement_id) {
        contrat.type_propriete_id = contratsSimples.value.type_contrat_id
      }
      contrat.proprietaire = contratsSimples.value.proprietaire.id

      contrat.frais_dentretien = contratsSimples.value.frais_dentretien
      contrat.echeancier_paiement = contratsSimples.value.echeancier_paiement
      contrat.piece_etat_lieux = contratsSimples.value.piece_etat_lieux
      contrat.responsabilite_entretien = contratsSimples.value.responsabilite_entretien
      contrat.agence_immobiliere = contratsSimples.value.agence_immobiliere
      contrat.appartement_id = contratsSimples.value.appartement_id
      contrat.type_contrat_id = contratsSimples.value.type_contrat_id
    })

    return {
      contrat,
      isResponsableEntretioen,
      isAuthorize,
      isPayementMethod,
      contratVente,
      contractTypes,
      echancePayementListe,
      validationFormDateDebutVente,
      dateDebutVenteRules,
      validationFormContratPdfFile,
      formPdfRules,
      dureeBailList,
      handlePdfSelected,
      handlePiecePdfSelected,
      isRenouvellement,
      number,

      form,
      items,
      cureDame,
      telephonefullPartenaire,
      telephonePersonneContacterfull,
      derniereCureDame,
      currentStep,
      fieldsEnfant,
      submitEnfantForm,
      handleEnfantSubmitModal,
      enfantGenre,
      inscritIj,
      myModal,
      removeItem,
      enfantForm,
      resetEnfantForm,
      formSubmitted,
      personnelRules,
      validationFormPersonnel,
      validationFormEtranger,
      etrangerRules,
      validationFormSituationMatrimoniale,
      situationMatrimonialeRules,
      validationFormInfoPartenaire,
      infoPartenaireRules,
      validationFormEnfant,
      enfantRules,
      enfantAddRules,
      validationFormParcoursSpirituel,
      parcoursSpirituelRules,
      validationFormAptitude,
      aptitudeRules,
      validationFormPersonneContacter,
      personneContacterRules,
      isLeader,
      etapesMariage,
      isMembreIcc,
      telephonefull,
      telephonefullLeader,
      config,
      required,
      email,
      appLogoImage,
      departements,
      familles,
      situationProfessionnelle,
      formations,
      typeVisa,
      situationMatrimoniale,
      dureeContratList,

    }
  },

}
</script>
<!-- @core/ -->
<style lang="scss">
@import '@core/scss/vue/libs/vue-wizard.scss';
@import '@core/scss/vue/libs/vue-select.scss';
@import '@core/scss/vue/libs/vue-flatpicker.scss';

.stepTitle {
    font-size: 11px !important;
}

.vue-form-wizard .wizard-navigation .wizard-nav li .wizard-icon-circle {
    width: 1.5rem !important;
    height: 1.5rem !important;
}
</style>
